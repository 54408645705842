import {
  ANDROID_CREATOR_APP_ID,
  CODE_REDEEM_CREATOR_APP_ID,
  IOS_CREATOR_APP_ID,
  PC_CREATOR_APP_ID,
  USS_CREATOR_APP_ID
} from "./constants";

export const hexToRgb = hex => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return r + "," + g + "," + b;
};

export const getSubPlatform = subscription => {
  switch (subscription.creatorAppId) {
    case ANDROID_CREATOR_APP_ID:
      return "ANDROID";
    case IOS_CREATOR_APP_ID:
      return "iOS";
    case PC_CREATOR_APP_ID:
    case USS_CREATOR_APP_ID:
    case CODE_REDEEM_CREATOR_APP_ID:
      return "PC";
    default:
      return null;
  }
};

export const getSubscriptionDescription = (subscriptionProduct, t) => {
  const subPlatform = getSubPlatform(subscriptionProduct);
  const duration = getSubDuration(subscriptionProduct);
  return subPlatform && duration
    ? t(`ghostItems.${subPlatform}-${duration}`)
    : null;
};

export const getSubDuration = subscriptionProduct => {
  switch (subscriptionProduct.duration) {
    case "1M":
      return "Monthly";
    case "3M":
      return "3Months";
    case "6M":
      return "6Months";
    case "12M":
      return "Annually";
    default:
      return null;
  }
};

export const sendAdobeEvent = (event, eventType = "clickevent") => {
  if (process.env.NODE_ENV === "development") {
    console.log(`tracking: ${eventType} -`, event);
  } else if (window.adobeAnalytics) {
    window.adobeAnalytics.track(eventType, event);
  }
};
