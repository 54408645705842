import styled, { keyframes } from "styled-components";

const loaderAnimation = keyframes`
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -616px;
  }
`;

export const Loader = styled.div`
  position: relative;
  min-height: 31.25rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28px;
    height: 28px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAmgAAAAcCAMAAADiHbOFAAAAM1BMVEUAAADxvBrxvBrxvBrxvBrxvBrxvBrxvBrxvBrxvBrxvBrxvBrxvBrxvBrxvBrxvBrxvBoM2pv9AAAAEHRSTlMA74BgMBDPn7/fcK9AIFCPIjI5twAAAs5JREFUeF7tXNuOqzAMXEiApEDX//+1R3uqaCq8XsuNEhHJ5aGqRrY19sRALv2aieavNh/4vvHlnFGrpj6IiGqcx5Si4nu86zgMBuNzRq3qfVQFWB6PRcImoukT38+UnndN+ka06SPXznkJYRlLaKh/U6Et8/w/wEq0Wu2ByQIdMelhmsKnnBPLY98r5BysnFF/aKJCaBilPIDsoBXGydhHmcX+mdfnlRPPBzCJV9y2qHGuF4p+takVNGHWEkB8c6y/0DJRlrA/CZ+nXCSaArdHd1XEpGDwI/I6jj85x32LDOD+qbXQAlEQMPhgz+iKXroLLUaDndC1jkO0/yb6BnnuU+paHHtdpUtViRC3zQSMP7sVsSOe/Zb+Euyu2KMz2nTAuxYGaSOhgZguGOkhW7dD1+E+5IQU8iXppQCSmBSsvtsdh2L34inEw2BjHHTuSn7EWv/kTBIocoZvo9CgVNEwRkWEGJFSAXnXCguLaxI/3mCBIaECP0VoOgYRgDMwNjCYHcQgxYMIpcFyzvPJ88LFFIiCJa88V8A0oYnPagZDdKTzvGAYuSCoC429cMTI4pbu+SoKxxATGE+MjmH6gWPXYr133Gt8gbNqZxkQRaiZKP8mBiGfJqFZ8xnTHgXMIjRg5Vnoiu1Ee7FBwXgBeffBN0Y/xyQxoZ3XCy0RJWB1PlmRK33ywQnMmk/USO9aOoYOahZaacufJiZdpkXefxu6z9AYinw3DqhR+3jQkii0TJSrAzrmWJmu6hHQMce6CM0xx7rcOh3zW2eTl4GVKL1j+O0vAzfggBp1fxnoP73h0xs+veETtj5haxda3FMUsJssQYXHOEtQvgQ1VSxB1S2q+6K6L6oPt03Itwn5NqHxNz5OdO+Nj5ssEMSmaeSNj76Ve80ttnL7Vu7+h1P8cArmE8c/nDLAcTs/bufH7fwAsR8gbujD/xKhC2f/S4R/wBMzk+rsfmAAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: auto 100%;
    animation: ${loaderAnimation} 1s steps(22) infinite;
  }
`;
