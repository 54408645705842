import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Container, useTheme } from "@mooncake/ui";
import EcomAPI from "@mooncake/mooncake-gateway-ecom";

import { fetchEnd } from "../../core/features/app/appSlice";
import { fetchUserSubscriptions } from "../../features/user/userSlice";
import NotLoggedIn from "../Sections/NotLoggedIn";

import Header from "../Sections/Header";
import SubscriptionHistory from "../Sections/SubscriptionHistory";
import { sendAdobeEvent } from "../../utils";

const Content = ({
  env,
  fetchEnd,
  fetchUserSubscriptions,
  locale,
  loading,
  authenticated,
  subscriptionlink,
  userAuthenticated
}) => {
  useEffect(() => {
    if (authenticated) {
      EcomAPI.environment =
        env && EcomAPI.ENVIRONMENTS[env.toUpperCase()]
          ? EcomAPI.ENVIRONMENTS[env.toUpperCase()]
          : EcomAPI.ENVIRONMENTS.UAT;
      EcomAPI.appId = "21a6b8b8-0e1a-4308-b2fc-bfd3470bd4d6";

      fetchUserSubscriptions()
        .then(() => fetchEnd())
        .catch(() => fetchEnd());
    }
  }, [authenticated, env, fetchEnd, fetchUserSubscriptions, locale]);

  useEffect(() => {
    sendAdobeEvent(
      {
        pageName: "purchase history",
        pageUrl: window.location.href,
        siteSection: "your subscription",
        mdmBrandId: 934,
        mdmInstallmentId: 5257,
        installmentName: "rocksmith plus",
        brandName: "rocksmith",
        ubisoftId: window.ubi_uid
      },
      "pageevent"
    );
  }, []);

  const theme = useTheme();
  return (
    !loading && (
      <Container
        maxWidth={"1200px"}
        margin={"auto"}
        padding={{
          default: "1.25rem",
          tablet: "3.125rem"
        }}
        backgroundColor={theme.colors.background.primary}
      >
        {userAuthenticated ? (
          <React.Fragment>
            <Header />
            <Container marginTop={"1.25rem"}>
              <SubscriptionHistory
                subscriptionlink={subscriptionlink}
                locale={locale}
              />
            </Container>
          </React.Fragment>
        ) : (
          <NotLoggedIn />
        )}
      </Container>
    )
  );
};

const mapStateToProps = state => ({
  loading: state.app.loading,
  userAuthenticated: state.user.authenticated
});

const mapDispatchToProps = {
  fetchEnd,
  fetchUserSubscriptions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Content));
