export default {
  translations: {
    header: {
      title: "Subscription History"
    },
    sorting: {
      date: "Date"
    },
    actions: {
      back: "Back",
      viewMore: "View More"
    },
    notLoggedIn: {
      title: "Unauthorized",
      message: "You must be logged in to have access to this page!"
    },
    ghostItems: {
      "PC-Monthly": "1-month subscription (PC)",
      "PC-3Months": "3-month subscription (PC)",
      "PC-6Months": "6-month subscription (PC)",
      "PC-Annually": "12-month subscription (PC)",
      "PS-Monthly": "1-month subscription (Playstation)",
      "PS-3Months": "3-month subscription (Playstation)",
      "PS-6Months": "6-month subscription (Playstation)",
      "PS-Annually": "12-month subscription (Playstation)",
      "XBOX-Monthly": "1-month subscription (XBOX)",
      "XBOX-3Months": "3-month subscription (XBOX)",
      "XBOX-6Months": "6-month subscription (XBOX)",
      "XBOX-Annually": "12-month subscription (XBOX)",
      "iOS-Monthly": "1-month subscription (iOS)",
      "iOS-3Months": "3-month subscription (iOS)",
      "iOS-6Months": "6-month subscription (iOS)",
      "iOS-Annually": "12-month subscription (iOS)",
      "ANDROID-Monthly": "1-month subscription (Android)",
      "ANDROID-3Months": "3-month subscription (Android)",
      "ANDROID-6Months": "6-month subscription (Android)",
      "ANDROID-Annually": "12-month subscription (Android)"
    }
  }
};
