export default {
  translations: {
    header: {
      title: "Historique de souscription"
    },
    sorting: {
      date: "Date"
    },
    actions: {
      back: "RETOUR",
      viewMore: "Afficher plus"
    },
    notLoggedIn: {
      title: "Non autorisé",
      message: "Vous devez vous identifier pour accéder à cette page&nbsp;!"
    },
    ghostItems: {
      "PC-Monthly": "Abonnement de 1 mois (PC)",
      "PC-3Months": "Abonnement de 3 mois (PC)",
      "PC-6Months": "Abonnement de 6 mois (PC)",
      "PC-Annually": "Abonnement de 12 mois (PC)",
      "PS-Monthly": "Abonnement de 1 mois (Playstation)",
      "PS-3Months": "Abonnement de 3 mois (Playstation)",
      "PS-6Months": "Abonnement de 6 mois (Playstation)",
      "PS-Annually": "Abonnement de 12 mois (Playstation)",
      "XBOX-Monthly": "Abonnement de 1 mois (XBOX)",
      "XBOX-3Months": "Abonnement de 3 mois (XBOX)",
      "XBOX-6Months": "Abonnement de 6 mois (XBOX)",
      "XBOX-Annually": "Abonnement de 12 mois (XBOX)",
      "iOS-Monthly": "Abonnement de 1 mois (iOS)",
      "iOS-3Months": "Abonnement de 3 mois (iOS)",
      "iOS-6Months": "Abonnement de 6 mois (iOS)",
      "iOS-Annually": "Abonnement de 12 mois (iOS)",
      "ANDROID-Monthly": "Abonnement de 1 mois (Android)",
      "ANDROID-3Months": "Abonnement de 3 mois (Android)",
      "ANDROID-6Months": "Abonnement de 6 mois (Android)",
      "ANDROID-Annually": "Abonnement de 12 mois (Android)"
    }
  }
};
