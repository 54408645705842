export default {
  translations: {
    header: {
      title: "Abonnements geschiedenis"
    },
    sorting: {
      date: "Datum"
    },
    actions: {
      back: "TERUG",
      viewMore: "Meer bekijken"
    },
    notLoggedIn: {
      title: "Geen toegang",
      message: "Je moet ingelogd zijn om deze pagina te openen."
    },
    ghostItems: {
      "PC-Monthly": "Abonnement van 1 maand (pc)",
      "PC-3Months": "Abonnement van 3 maanden (pc)",
      "PC-6Months": "Abonnement van 6 maanden (pc)",
      "PC-Annually": "Abonnement van 12 maanden (pc)",
      "PS-Monthly": "Abonnement van 1 maand (Playstation)",
      "PS-3Months": "Abonnement van 3 maanden (Playstation)",
      "PS-6Months": "Abonnement van 6 maanden (Playstation)",
      "PS-Annually": "Abonnement van 12 maanden (Playstation)",
      "XBOX-Monthly": "Abonnement van 1 maand (XBOX)",
      "XBOX-3Months": "Abonnement van 3 maanden (XBOX)",
      "XBOX-6Months": "Abonnement van 6 maanden (XBOX)",
      "XBOX-Annually": "Abonnement van 12 maanden (XBOX)",
      "iOS-Monthly": "Abonnement van 1 maand (iOS)",
      "iOS-3Months": "Abonnement van 3 maanden (iOS)",
      "iOS-6Months": "Abonnement van 6 maanden (iOS)",
      "iOS-Annually": "Abonnement van 12 maanden (iOS)",
      "ANDROID-Monthly": "Abonnement van 1 maand (Android)",
      "ANDROID-3Months": "Abonnement van 3 maanden (Android)",
      "ANDROID-6Months": "Abonnement van 6 maanden (Android)",
      "ANDROID-Annually": "Abonnement van 12 maanden (Android)"
    }
  }
};
