// Libs
import React from "react";
import PropTypes from "prop-types";
import { CustomAppPropTypes, CustomAppDefaultProps } from "../AppProps";
import { Provider } from "react-redux";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import computedTheme from "../theme";

// Store
import store from "./store";
import { initialize } from "./features/app/appSlice";

// Components
import { ThemeProvider } from "@mooncake/ui";
import MicroFrontend from "./MicroFrontend";

class App extends React.Component {
  constructor(props) {
    super(props);

    // select language from micro element component
    if (i18n.language !== props.locale) {
      i18n.changeLanguage(props.locale);
    }
  }

  componentDidMount() {
    store.dispatch(
      initialize({
        themeKey: this.props.theme,
        locale: this.props.locale
      })
    );
  }

  render() {
    const { theme } = this.props;
    return (
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={computedTheme(theme)}>
            <MicroFrontend {...this.props} />
          </ThemeProvider>
        </I18nextProvider>
      </Provider>
    );
  }
}

// Props
export const AppPropTypes = {
  locale: PropTypes.string,
  theme: PropTypes.string,
  ...CustomAppPropTypes
};

export const AppDefaultProps = {
  locale: "en",
  theme: "store",
  ...CustomAppDefaultProps
};

App.propTypes = AppPropTypes;
App.defaultProps = AppDefaultProps;

export default App;
