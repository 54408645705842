export default {
  translations: {
    header: {
      title: "구독 내역"
    },
    sorting: {
      date: "날짜"
    },
    actions: {
      back: "뒤로가기",
      viewMore: "더 보기"
    },
    notLoggedIn: {
      title: "인증 필요",
      message: "이 페이지를 보려면 로그인 해야 합니다!"
    },
    ghostItems: {
      "PC-Monthly": "1개월 구독 (PC) ",
      "PC-3Months": "3개월 구독 (PC) ",
      "PC-6Months": "6개월 구독 (PC) ",
      "PC-Annually": "12개월 구독 (PC) ",
      "PS-Monthly": "1개월 구독 (Playstation)",
      "PS-3Months": "3개월 구독 (Playstation)",
      "PS-6Months": "6개월 구독 (Playstation)",
      "PS-Annually": "12개월 구독 (Playstation)",
      "XBOX-Monthly": "1개월 구독 (XBOX)",
      "XBOX-3Months": "3개월 구독 (XBOX)",
      "XBOX-6Months": "6개월 구독 (XBOX)",
      "XBOX-Annually": "12개월 구독 (XBOX)",
      "iOS-Monthly": "1개월 구독 (iOS)",
      "iOS-3Months": "3개월 구독 (iOS)",
      "iOS-6Months": "6개월 구독 (iOS)",
      "iOS-Annually": "12개월 구독 (iOS)",
      "ANDROID-Monthly": "1개월 구독 (Android)",
      "ANDROID-3Months": "3개월 구독 (Android)",
      "ANDROID-6Months": "6개월 구독 (Android)",
      "ANDROID-Annually": "12개월 구독 (Android)"
    }
  }
};
