import styled from "styled-components";
import { hexToRgb } from "../../../utils";

import { Container } from "@mooncake/ui";

export const CustomContainer = styled(Container)`
  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    width: 1.875rem;
  }
  &::before {
    background-image: linear-gradient(
      -90deg,
      rgba(${props => hexToRgb(props.backgroundColorForGradients)}, 0) 0%,
      rgba(${props => hexToRgb(props.backgroundColorForGradients)}, 1) 100%
    );
    left: 0;
  }
  &::after {
    background-image: linear-gradient(
      90deg,
      rgba(${props => hexToRgb(props.backgroundColorForGradients)}, 0) 0%,
      rgba(${props => hexToRgb(props.backgroundColorForGradients)}, 1) 100%
    );
    right: 0;
  }
`;
