export default {
  translations: {
    header: {
      title: "訂閱歷史記錄"
    },
    sorting: {
      date: "日期"
    },
    actions: {
      back: "返回",
      viewMore: "查看更多"
    },
    notLoggedIn: {
      title: "未經授權",
      message: "你必須登入才能存取這個頁面！"
    },
    ghostItems: {
      "PC-Monthly": "訂購服務 1 個月（PC）",
      "PC-3Months": "訂購服務 3 個月（PC）",
      "PC-6Months": "訂購服務 6 個月（PC）",
      "PC-Annually": "訂購服務 12 個月（PC）",
      "PS-Monthly": "1 個月訂閱（Playstation）",
      "PS-3Months": "3 個月訂閱（Playstation）",
      "PS-6Months": "6 個月訂閱（Playstation）",
      "PS-Annually": "12 個月訂閱（Playstation）",
      "XBOX-Monthly": "1 個月訂閱（XBOX）",
      "XBOX-3Months": "3 個月訂閱（XBOX）",
      "XBOX-6Months": "6 個月訂閱（XBOX）",
      "XBOX-Annually": "12 個月訂閱（XBOX）",
      "iOS-Monthly": "1 個月訂閱（iOS）",
      "iOS-3Months": "3 個月訂閱（iOS）",
      "iOS-6Months": "6 個月訂閱（iOS）",
      "iOS-Annually": "12 個月訂閱（iOS）",
      "ANDROID-Monthly": "1 個月訂閱（Android）",
      "ANDROID-3Months": "3 個月訂閱（Android）",
      "ANDROID-6Months": "6 個月訂閱（Android）",
      "ANDROID-Annually": "12 個月訂閱（Android）"
    }
  }
};
