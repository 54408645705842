import React from "react";
import { withTranslation } from "react-i18next";
import { withTheme } from "styled-components";

import Separator from "../../Elements/Separator";
import { Container, Text } from "@mooncake/ui";

const Header = ({ t, theme }) => {
  return (
    <Container textAlign={"center"}>
      <Text.Heading bold primary margin={"0"}>
        {t("header.title")}
      </Text.Heading>
      <Separator backgroundColor={theme.colors.background.primary} />
    </Container>
  );
};

export default withTranslation()(withTheme(Header));
