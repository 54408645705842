import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import { withTheme } from "styled-components";

import { getSubscriptionDescription, sendAdobeEvent } from "../../../utils";

import { Container, Icon, Text } from "@mooncake/ui";
import { IbexSorting, IbexVolume } from "@mooncake/ui/lib/icons";
import { Button } from "../../Elements/Button";
import { FilterButton, List, Item } from "./styles";

const SubscriptionHistory = ({
  subscriptionProducts,
  t,
  theme,
  subscriptionlink,
  locale
}) => {
  const subscriptions = subscriptionProducts;

  const nbItemsPerPage = 6;
  const [indexTransactions, setIndexTransactions] = useState(1);
  const [sorting, setSorting] = useState("desc");

  const listTransactionsSorted =
    sorting === "desc"
      ? subscriptions.slice().sort((a, b) => {
          const aDate = a.startedAt || a.date;
          const bDate = b.startedAt || b.date;
          return aDate < bDate ? 1 : -1;
        })
      : subscriptions.slice().sort((a, b) => {
          const aDate = a.startedAt || a.date;
          const bDate = b.startedAt || b.date;
          return aDate > bDate ? 1 : -1;
        });

  const displayedTransactions =
    listTransactionsSorted.length > 0
      ? listTransactionsSorted.slice(0, indexTransactions * nbItemsPerPage)
      : [];

  const toggleSorting = () => {
    setSorting(sorting === "asc" ? "desc" : "asc");
  };

  return (
    displayedTransactions.length > 0 && (
      <Container
        maxWidth={"600px"}
        margin={"0 auto 1.875rem"}
        textAlign={"left"}
      >
        <FilterButton
          onClick={() => toggleSorting()}
          inverted={sorting === "asc"}
        >
          {t("sorting.date")}{" "}
          <Icon color={theme.colors.text.primary} icon={IbexSorting} />
        </FilterButton>
        <List>
          {displayedTransactions.map((subscription, indexTransaction) => (
            <Item key={indexTransaction}>
              <Text.Paragraph primary margin={"0"}>
                {moment(subscription.startedAt || subscription.date)
                  .utc()
                  .format("L")}
              </Text.Paragraph>
              <Container
                color={theme.colors.text.secondary}
                fontSize={{
                  default: "1.2rem",
                  tablet: "1.5rem"
                }}
                margin={{
                  default: "0 0.625rem",
                  tablet: "0 1.25rem"
                }}
              >
                •
              </Container>
              <Text.Paragraph
                primary
                flex={"1"}
                margin={"0"}
                dangerouslySetInnerHTML={{
                  __html: getSubscriptionDescription(subscription, t)
                }}
              />
            </Item>
          ))}
        </List>
        <Container
          marginTop={"2rem"}
          textAlign={"center"}
          display={"flex"}
          flexDirection={{
            default: "column",
            mobile: "row"
          }}
          justifyContent={"center"}
        >
          <Container
            margin={{
              default: "0 0 0.5rem",
              mobile: "0 1rem"
            }}
          >
            <Button
              secondary
              forwardedAs={"a"}
              href={`/${locale}${subscriptionlink}`}
              onClick={() =>
                sendAdobeEvent({
                  location: "purchase history",
                  category: "action",
                  action: "back"
                })
              }
            >
              <Container marginRight={"auto"}>{t("actions.back")}</Container>
              <Icon icon={IbexVolume} color="currentColor" />
            </Button>
          </Container>
          {indexTransactions * nbItemsPerPage <
            listTransactionsSorted.length && (
            <Container
              margin={{
                default: "0.5rem 0 0",
                mobile: "0 1rem"
              }}
            >
              <Button
                secondary
                onClick={() => {
                  setIndexTransactions(indexTransactions + 1);
                  sendAdobeEvent({
                    location: "purchase history",
                    category: "action",
                    action: "view more"
                  });
                }}
              >
                <Container marginRight={"auto"}>
                  {t("actions.viewMore")}
                </Container>
                <Icon icon={IbexVolume} color="currentColor" />
              </Button>
            </Container>
          )}
        </Container>
      </Container>
    )
  );
};

const mapStateToProps = state => ({
  subscriptionProducts: state.user.subscriptionProducts
});

export default connect(mapStateToProps)(
  withTranslation()(withTheme(SubscriptionHistory))
);
