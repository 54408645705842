import styled from "styled-components";
import { Icon } from "@mooncake/ui";

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  appearance: none;
  border: 0;
  background: transparent;
  padding: 0 0 0.5rem 0;
  border-bottom: 2px solid ${props => props.theme.colors.primary.normal};
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  letter-spacing: 4px;
  width: 100%;
  text-align: left;
  cursor: pointer;

  ${Icon} {
    margin-left: 1rem;
    ${props =>
      props.inverted
        ? `
      transform: scaleY(-1);
  `
        : ``}
  }
`;

export const List = styled.ul`
  width: 100%;
  display: block;
  padding: 0;
  list-style: none;
  margin: 0;
`;

export const Item = styled.li`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text.primary};
  line-height: 1rem;
  padding: 1rem;
  background-color: ${props => props.theme.colors.background.secondary};
`;
