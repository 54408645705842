export default {
  translations: {
    header: {
      title: "История подписки"
    },
    sorting: {
      date: "Дата"
    },
    actions: {
      back: "НАЗАД",
      viewMore: "Подробнее"
    },
    notLoggedIn: {
      title: "Необходимо авторизоваться",
      message: "Для доступа к этой странице нужно войти в учетную запись!"
    },
    ghostItems: {
      "PC-Monthly": "Подписка на 1 месяц (PC)",
      "PC-3Months": "Подписка на 3 месяца (PC)",
      "PC-6Months": "Подписка на 6 месяцев (PC)",
      "PC-Annually": "Подписка на 12 месяцев (PC)",
      "PS-Monthly": "1 месяц подписки (PlayStation)",
      "PS-3Months": "3 месяца подписки (PlayStation)",
      "PS-6Months": "6 месяцев подписки (PlayStation)",
      "PS-Annually": "12 месяцев подписки (PlayStation)",
      "XBOX-Monthly": "1 месяц подписки (XBOX)",
      "XBOX-3Months": "3 месяца подписки (XBOX)",
      "XBOX-6Months": "6 месяцев подписки (XBOX)",
      "XBOX-Annually": "12 месяцев подписки (XBOX)",
      "iOS-Monthly": "1 месяц подписки для iOS",
      "iOS-3Months": "3 месяца подписки для iOS",
      "iOS-6Months": "6 месяцев подписки для iOS",
      "iOS-Annually": "12 месяцев подписки для iOS",
      "ANDROID-Monthly": "1 месяц подписки для Android",
      "ANDROID-3Months": "3 месяца подписки для Android",
      "ANDROID-6Months": "6 месяцев подписки для Android",
      "ANDROID-Annually": "12 месяцев подписки для Android"
    }
  }
};
