import React from "react";
import { withTheme } from "styled-components";

import { Container } from "@mooncake/ui";
import { Loader } from "./styles";

const AppLoader = ({ theme }) => (
  <Container
    maxWidth={"1200px"}
    margin={"auto"}
    padding={{
      default: "1.25rem",
      tablet: "3.125rem"
    }}
    backgroundColor={theme.colors.background.primary}
  >
    <Loader />
  </Container>
);

export default withTheme(AppLoader);
