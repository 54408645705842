export default {
  translations: {
    header: {
      title: "Historia subskrypcji"
    },
    sorting: {
      date: "Data"
    },
    actions: {
      back: "Powrót",
      viewMore: "Zobacz więcej"
    },
    notLoggedIn: {
      title: "Brak autoryzacji",
      message: "Dostęp do tej strony wymaga zalogowania!"
    },
    ghostItems: {
      "PC-Monthly": "1-miesięczna subskrypcja (PC)",
      "PC-3Months": "3-miesięczna subskrypcja (PC)",
      "PC-6Months": "6-miesięczna subskrypcja (PC)",
      "PC-Annually": "12-miesięczna subskrypcja (PC)",
      "PS-Monthly": "1 miesiąc subskrypcji (Playstation)",
      "PS-3Months": "3 miesiące subskrypcji (Playstation)",
      "PS-6Months": "6 miesięcy subskrypcji (Playstation)",
      "PS-Annually": "12 miesięcy subskrypcji (Playstation)",
      "XBOX-Monthly": "1 miesiąc subskrypcji (XBOX)",
      "XBOX-3Months": "3 miesiące subskrypcji (XBOX)",
      "XBOX-6Months": "6 miesięcy subskrypcji (XBOX)",
      "XBOX-Annually": "12 miesięcy subskrypcji (XBOX)",
      "iOS-Monthly": "1 miesiąc subskrypcji (iOS)",
      "iOS-3Months": "3 miesiące subskrypcji (iOS)",
      "iOS-6Months": "6 miesięcy subskrypcji (iOS)",
      "iOS-Annually": "12 miesięcy subskrypcji (iOS)",
      "ANDROID-Monthly": "1 miesiąc subskrypcji (Android)",
      "ANDROID-3Months": "3 miesiące subskrypcji (Android)",
      "ANDROID-6Months": "6 miesięcy subskrypcji (Android)",
      "ANDROID-Annually": "12 miesięcy subskrypcji (Android)"
    }
  }
};
