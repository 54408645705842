export default {
  translations: {
    header: {
      title: "سجل الاشتراكات"
    },
    sorting: {
      date: "التاريخ"
    },
    actions: {
      back: "رجوع",
      viewMore: "عرض المزيد"
    },
    notLoggedIn: {
      title: "غير مصرَّح",
      message: "لا بد من تسجيل الدخول للوصول إلى هذه الصفحة!"
    },
    ghostItems: {
      "PC-Monthly": "اشتراك شهر 1 (على جهاز الكمبيوتر)",
      "PC-3Months": "اشتراك 3 أشهر (على جهاز الكمبيوتر)",
      "PC-6Months": "اشتراك 6 شهر (على جهاز الكمبيوتر)",
      "PC-Annually": "اشتراك 12 شهر (على جهاز الكمبيوتر)",
      "PS-Monthly": "اشتراك لمدة شهر (على Playstation)",
      "PS-3Months": "اشتراك لمدة 3 أشهر (على Playstation)",
      "PS-6Months": "اشتراك لمدة 6 أشهر (على Playstation)",
      "PS-Annually": "اشتراك لمدة 12 شهرًا (على Playstation)",
      "XBOX-Monthly": "اشتراك لمدة شهر (على XBOX)",
      "XBOX-3Months": "اشتراك لمدة 3 أشهر (على XBOX)",
      "XBOX-6Months": "اشتراك لمدة 6 أشهر (على XBOX)",
      "XBOX-Annually": "اشتراك لمدة 12 شهرًا (على XBOX)",
      "iOS-Monthly": "اشتراك لمدة شهر (على iOS)",
      "iOS-3Months": "اشتراك لمدة 3 أشهر (على iOS)",
      "iOS-6Months": "اشتراك لمدة 6 أشهر (على iOS)",
      "iOS-Annually": "اشتراك لمدة 12 شهرًا (على iOS)",
      "ANDROID-Monthly": "اشتراك لمدة شهر (على Android)",
      "ANDROID-3Months": "اشتراك لمدة 3 أشهر (على Android)",
      "ANDROID-6Months": "اشتراك لمدة 6 أشهر (على Android)",
      "ANDROID-Annually": "اشتراك لمدة 12 شهرًا (على Android)"
    }
  }
};
