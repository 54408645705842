import PropTypes from "prop-types";

export const CustomAppPropTypes = {
  authenticated: PropTypes.bool.isRequired,
  subscriptionlink: PropTypes.string.isRequired,
  env: PropTypes.string
};

export const CustomAppDefaultProps = {
  authenticated: false,
  subscriptionlink: "/game/rocksmith/ibex/subscription",
  env: "UAT"
};
