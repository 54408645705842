import kebabCase from "lodash/kebabCase";
import packageJson from "../package";
import MicroElement from "./core";

class LegacyMicroElement extends MicroElement {}

const elementName = kebabCase(packageJson.name);
if (!customElements.get(elementName)) {
  customElements.define(elementName, MicroElement);
  // Define element with new and old name for backward compatibility
  customElements.define("ibex-transactions", LegacyMicroElement);
} else {
  console.error(
    `A custom element with the name "${elementName}" already exists in the page. We can't define it again.`
  );
}
