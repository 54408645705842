export default {
  translations: {
    header: {
      title: "Subscription History"
    },
    sorting: {
      date: "Datum"
    },
    actions: {
      back: "Tillbaka",
      viewMore: "Se mer"
    },
    notLoggedIn: {
      title: "Inte auktoriserad",
      message: "Du måste vara inloggad för att se den här sidan!"
    },
    ghostItems: {
      "PC-Monthly": "1 månads prenumeration (PC)",
      "PC-3Months": "3 månaders prenumeration (PC)",
      "PC-6Months": "6 månaders prenumeration (PC)",
      "PC-Annually": "12 månaders prenumeration (PC)",
      "PS-Monthly": "1 månads prenumeration (Playstation)",
      "PS-3Months": "3 månaders prenumeration (Playstation)",
      "PS-6Months": "6 månaders prenumeration (Playstation)",
      "PS-Annually": "12 månaders prenumeration (Playstation)",
      "XBOX-Monthly": "1 månads prenumeration (XBOX)",
      "XBOX-3Months": "3 månaders prenumeration (XBOX)",
      "XBOX-6Months": "6 månaders prenumeration (XBOX)",
      "XBOX-Annually": "12 månaders prenumeration (XBOX)",
      "iOS-Monthly": "1 månads prenumeration (iOS)",
      "iOS-3Months": "3 månaders prenumeration (iOS)",
      "iOS-6Months": "6 månaders prenumeration (iOS)",
      "iOS-Annually": "12 månaders prenumeration (iOS)",
      "ANDROID-Monthly": "1 månads prenumeration (Android)",
      "ANDROID-3Months": "3 månaders prenumeration (Android)",
      "ANDROID-6Months": "6 månaders prenumeration (Android)",
      "ANDROID-Annually": "12 månaders prenumeration (Android)"
    }
  }
};
