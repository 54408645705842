export default {
  translations: {
    header: {
      title: "Subscription History"
    },
    sorting: {
      date: "Dato"
    },
    actions: {
      back: "Gå tilbage",
      viewMore: "Vis mere"
    },
    notLoggedIn: {
      title: "Uautoriseret",
      message: "Du skal være logget ind for at have adgang til denne side!"
    },
    ghostItems: {
      "PC-Monthly": "1 måneds abonnement (pc)",
      "PC-3Months": "3 måneders abonnement (pc)",
      "PC-6Months": "6 måneders abonnement (pc)",
      "PC-Annually": "12 måneders abonnement (pc)",
      "PS-Monthly": "1 måneds abonnement (Playstation)",
      "PS-3Months": "3 måneders abonnement (Playstation)",
      "PS-6Months": "6 måneders abonnement (Playstation)",
      "PS-Annually": "12 måneders abonnement (Playstation)",
      "XBOX-Monthly": "1 måneds abonnement (XBOX)",
      "XBOX-3Months": "3 måneders abonnement (XBOX)",
      "XBOX-6Months": "6 måneders abonnement (XBOX)",
      "XBOX-Annually": "12 måneders abonnement (XBOX)",
      "iOS-Monthly": "1 måneds abonnement (iOS)",
      "iOS-3Months": "3 måneders abonnement (iOS)",
      "iOS-6Months": "6 måneders abonnement (iOS)",
      "iOS-Annually": "12 måneders abonnement (iOS)",
      "ANDROID-Monthly": "1 måneds abonnement (iOS)",
      "ANDROID-3Months": "3 måneders abonnement (iOS)",
      "ANDROID-6Months": "6 måneders abonnement (iOS)",
      "ANDROID-Annually": "12 måneders abonnement (iOS)"
    }
  }
};
