/**
 * Instruction to add language manually: The country/locale code should be in kebab case
 * Example: en-US.json
 * import enUS from "./en-US";
 * export default {
 *   ...
 *   "en-US": enUS
 * }
 */
import arAE from "./ar-ae";
import daDK from "./da-dk";
import de from "./de";
import en from "./en";
import es from "./es";
import fi from "./fi";
import fr from "./fr";
import it from "./it";
import jaJP from "./ja-jp";
import koKR from "./ko-kr";
import nl from "./nl";
import no from "./no";
import pl from "./pl";
import ptBR from "./pt-br";
import ru from "./ru";
import svSE from "./sv-se";
import zh from "./zh";

export default {
  "ar-AE": arAE,
  "da-DK": daDK,
  de,
  en,
  es,
  fi,
  fr,
  it,
  "ja-JP": jaJP,
  "ko-KR": koKR,
  nl,
  no,
  pl,
  "pt-BR": ptBR,
  ru,
  "sv-SE": svSE,
  zh
};
