import { createSlice } from "@reduxjs/toolkit";
import { setError } from "../../core/features/app/appSlice";
import i18n from "../../core/i18n";

import { fetchUssSubscriptionsV2 } from "@mooncake/mooncake-gateway-ecom/events/ussSubscriptions";

const userSlice = createSlice({
  name: "user",
  initialState: {
    authenticated: false,
    inventory: [],
    subscriptionProducts: []
  },
  reducers: {
    setAuthenticated(state, action) {
      state.authenticated = action.payload;
    },
    setSubscriptionProducts(state, action) {
      state.subscriptionProducts = action.payload;
    },
    setInventory(state, action) {
      state.inventory = action.payload;
    }
  }
});

export const fetchUserSubscriptions = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetchUssSubscriptionsV2(
      {
        additionalParams: "and type eq 'ibex' &extraFields[]=products"
      },
      response => {
        if (!response.error) {
          dispatch(setAuthenticated(true));
          let productsList = [];
          response.resource.data.forEach(subscription => {
            if (subscription.products.length) {
              productsList = productsList.concat(subscription.products);
            }
          });
          dispatch(setSubscriptionProducts(productsList));
          resolve(response.resource);
        } else {
          console.error(response.error);
          dispatch(setAuthenticated(false));
          dispatch(setError(i18n.t("errors.fetchSecondaryStoreTransactions")));
          reject(response.error);
        }
      }
    )();
  });
};

// eslint-disable-next-line no-empty-pattern
export const { setAuthenticated, setSubscriptionProducts } = userSlice.actions;

export default userSlice.reducer;
