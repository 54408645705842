import React from "react";
import { withTheme } from "styled-components";

import { CustomContainer } from "./styles";

const Separator = ({ backgroundColor, theme }) => {
  const separatorPath = require("../../../assets/images/separator.svg");
  const backgroundColorForGradients =
    backgroundColor || theme.colors.background.primary;
  return (
    <CustomContainer
      margin={"0 auto"}
      width={"6.125rem"}
      height={"3.125rem"}
      backgroundImage={`url(${separatorPath})`}
      backgroundPosition={"0 50%"}
      backgroundSize={"70% 70%"}
      backgroundRepeat={"repeat-x"}
      position={"relative"}
      backgroundColorForGradients={backgroundColorForGradients}
    />
  );
};

export default withTheme(Separator);
