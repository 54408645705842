export default {
  translations: {
    header: {
      title: "Storico degli abbonamenti"
    },
    sorting: {
      date: "Data"
    },
    actions: {
      back: "TORNA INDIETRO",
      viewMore: "Visualizza di più"
    },
    notLoggedIn: {
      title: "Non autorizzato",
      message: "Devi aver effettuato l'accesso per visualizzare questa pagina!"
    },
    ghostItems: {
      "PC-Monthly": "Abbonamento di 1 mese (PC)",
      "PC-3Months": "Abbonamento di 3 mesi (PC)",
      "PC-6Months": "Abbonamento di 6 mesi (PC)",
      "PC-Annually": "Abbonamento di 12 mesi (PC)",
      "PS-Monthly": "Abbonamento di 1 mese (Playstation)",
      "PS-3Months": "Abbonamento di 3 mesi (Playstation)",
      "PS-6Months": "Abbonamento di 6 mesi (Playstation)",
      "PS-Annually": "Abbonamento di 12 mesi (Playstation)",
      "XBOX-Monthly": "Abbonamento di 1 mese (XBOX)",
      "XBOX-3Months": "Abbonamento di 3 mesi (XBOX)",
      "XBOX-6Months": "Abbonamento di 6 mesi (XBOX)",
      "XBOX-Annually": "Abbonamento di 12 mesi (XBOX)",
      "iOS-Monthly": "Abbonamento di 1 mese (iOS)",
      "iOS-3Months": "Abbonamento di 3 mesi (iOS)",
      "iOS-6Months": "Abbonamento di 6 mesi (iOS)",
      "iOS-Annually": "Abbonamento di 12 mesi (iOS)",
      "ANDROID-Monthly": "Abbonamento di 1 mese (Android)",
      "ANDROID-3Months": "Abbonamento di 3 mesi (Android)",
      "ANDROID-6Months": "Abbonamento di 6 mesi (Android)",
      "ANDROID-Annually": "Abbonamento di 12 mesi (Android)"
    }
  }
};
