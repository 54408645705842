export default {
  translations: {
    header: {
      title: "Histórico de Assinaturas"
    },
    sorting: {
      date: "Data"
    },
    actions: {
      back: "Voltar",
      viewMore: "Ver mais"
    },
    notLoggedIn: {
      title: "Não autorizado",
      message: "Você precisa se conectar para acessar essa página!"
    },
    ghostItems: {
      "PC-Monthly": "Assinatura de 1 mês (PC)",
      "PC-3Months": "Assinatura de 3 mês (PC)",
      "PC-6Months": "Assinatura de 6 mês (PC)",
      "PC-Annually": "Assinatura de 12 mês (PC)",
      "PS-Monthly": "Assinatura de 1 mês (PlayStation)",
      "PS-3Months": "Assinatura de 3 meses (PlayStation)",
      "PS-6Months": "Assinatura de 6 meses (PlayStation)",
      "PS-Annually": "Assinatura de 12 meses (PlayStation)",
      "XBOX-Monthly": "Assinatura de 1 mês (XBOX)",
      "XBOX-3Months": "Assinatura de 3 meses (XBOX)",
      "XBOX-6Months": "Assinatura de 6 meses (XBOX)",
      "XBOX-Annually": "Assinatura de 12 meses (XBOX)",
      "iOS-Monthly": "Assinatura de 1 mês (iOS)",
      "iOS-3Months": "Assinatura de 3 meses (iOS)",
      "iOS-6Months": "Assinatura de 6 meses (iOS)",
      "iOS-Annually": "Assinatura de 12 meses (iOS)",
      "ANDROID-Monthly": "Assinatura de 1 mês (Android)",
      "ANDROID-3Months": "Assinatura de 3 meses (Android)",
      "ANDROID-6Months": "Assinatura de 6 meses (Android)",
      "ANDROID-Annually": "Assinatura de 12 meses (Android)"
    }
  }
};
